// extracted by mini-css-extract-plugin
export var mainContainer = "search-parkings-module--mainContainer--2RtU5";
export var sectionTitle = "search-parkings-module--sectionTitle--2Hs3M";
export var searchParkings = "search-parkings-module--searchParkings--2WMUR";
export var searchContainer = "search-parkings-module--searchContainer--mQjEJ";
export var searchFilterRow = "search-parkings-module--searchFilterRow--3AVVd";
export var resultContainer = "search-parkings-module--resultContainer--CIi3C";
export var pcMap = "search-parkings-module--pcMap--1AJG2";
export var searchInMapBoundsButton = "search-parkings-module--searchInMapBoundsButton--loO1M";
export var searchResult = "search-parkings-module--searchResult--1G6yj";
export var searchResultList = "search-parkings-module--searchResultList--1Xyf8";
export var findFromList = "search-parkings-module--findFromList--1lQ9F";
export var mobileMap = "search-parkings-module--mobileMap--26oy8";
export var openListButton = "search-parkings-module--openListButton--1fqt9";
export var mobileSearchResultListOnMap = "search-parkings-module--mobileSearchResultListOnMap--IwPuq";
export var searchResultListItem = "search-parkings-module--searchResultListItem--31z-q";