import { LatLng } from "../__generated__/proto/google/type/latlng"
import { Parking } from "../__generated__/proto/itech/motorist/pksha/v1/parking"
import { APIStatus } from "../api-status"
import * as config from "../config"
import { SearchFilterType, SearchOrderType } from "../types"

export type SearchParkingsState = {
  // moodels
  parkings: Parking[]
  displayingParkings: Parking[] // parkings that are filtered and sorted

  // selections
  currentPage: number
  selectedParking: Parking | null
  searchFilters: (keyof typeof SearchFilterType)[]
  searchOrder: keyof typeof SearchOrderType
  currentMapBounds: google.maps.LatLngBounds | null
  currentMapCenter: LatLng | null
  currentMapZoom: number

  // conditions
  showSearchButton: boolean // condition for "このエリアで検索" button
  mobileBottomSheetOpen: boolean

  // API statuses
  loadParkingsStatus: APIStatus
  searchStatus: APIStatus
}

export const initialSearchParkingsState: SearchParkingsState = {
  parkings: [],
  displayingParkings: [],
  currentPage: 1,
  selectedParking: null,
  searchFilters: [],
  searchOrder: "DISTANCE",
  currentMapBounds: null,
  currentMapCenter: null,
  currentMapZoom: config.DEFAULT_MAP_ZOOM,
  showSearchButton: false,
  mobileBottomSheetOpen: false,
  loadParkingsStatus: "pending",
  searchStatus: "pending",
}
