import React, { Dispatch, ReactElement } from "react"
import { LatLng } from "../__generated__/proto/google/type/latlng"
import { Parking } from "../__generated__/proto/itech/motorist/pksha/v1/parking"
import {
  FilterParkingsUseCase,
  GetDistanceUseCase,
  GetParkingCurrentFeeUseCase,
  SortParkingsUseCase,
} from "../domain/use-cases"
import { SearchFilterType, SearchOrderType } from "../types"
import { SearchParkingsAction } from "./search-parkings-action"
import {
  initialSearchParkingsState,
  SearchParkingsState,
} from "./search-parkings-state"

const getDistanceUseCase = new GetDistanceUseCase()
const getParkingCurrentFeeUseCase = new GetParkingCurrentFeeUseCase()
const sortParkingsUseCase = new SortParkingsUseCase(
  getDistanceUseCase,
  getParkingCurrentFeeUseCase
)
const filterParkingsUseCase = new FilterParkingsUseCase()

const reducer = (
  state: SearchParkingsState,
  action: SearchParkingsAction
): SearchParkingsState => {
  switch (action.type) {
    case "LOAD_PARKINGS":
      return {
        ...state,
        showSearchButton: false,
        loadParkingsStatus: "loading",
      }
    case "LOAD_PARKINGS_SUCCESS":
      return {
        ...state,
        parkings: action.parkings,
        displayingParkings: getDisplayingParkings(
          action.parkings,
          state.searchFilters,
          state.searchOrder,
          state.currentMapCenter
        ),
        loadParkingsStatus: "succeeded",
      }
    case "LOAD_PARKINGS_FAILURE":
      return {
        ...state,
        loadParkingsStatus: "failed",
      }
    case "SET_CURRENT_PAGE":
      return { ...state, currentPage: action.currentPage }
    case "SELECT_PARKING":
      return { ...state, selectedParking: action.parking }
    case "ADD_SEARCH_FILTER":
      const addedSearchFilters = [...state.searchFilters, action.searchFilter]
      return {
        ...state,
        searchFilters: addedSearchFilters,
        displayingParkings: getDisplayingParkings(
          state.parkings,
          addedSearchFilters,
          state.searchOrder,
          state.currentMapCenter
        ),
      }
    case "REMOVE_SEARCH_FILTER":
      const removedSearchFilters = state.searchFilters.filter(
        searchFilter => searchFilter !== action.searchFilter
      )
      return {
        ...state,
        searchFilters: removedSearchFilters,
        displayingParkings: getDisplayingParkings(
          state.parkings,
          removedSearchFilters,
          state.searchOrder,
          state.currentMapCenter
        ),
      }
    case "SET_SEARCH_ORDER":
      return {
        ...state,
        searchOrder: action.searchOrder,
        displayingParkings: getDisplayingParkings(
          state.parkings,
          state.searchFilters,
          action.searchOrder,
          state.currentMapCenter
        ),
      }
    case "SET_CURRENT_MAP_BOUNDS":
      return { ...state, currentMapBounds: action.mapBounds }
    case "SET_CURRENT_MAP_CENTER":
      return {
        ...state,
        currentMapCenter: action.mapCenter,
        showSearchButton: true,
        displayingParkings: getDisplayingParkings(
          state.parkings,
          state.searchFilters,
          state.searchOrder,
          action.mapCenter
        ),
      }
    case "SET_CURRENT_MAP_ZOOM":
      return {
        ...state,
        currentMapZoom: action.mapZoom,
        showSearchButton: true,
      }
    case "SET_SHOW_SEARCH_BUTTON":
      return { ...state, showSearchButton: action.showSearchButton }
    case "SET_MOBILE_BOTTOM_SHEET_OPEN":
      return { ...state, mobileBottomSheetOpen: action.mobileBottomSheetOpen }
    case "SET_SEARCH_STATUS":
      return { ...state, searchStatus: action.searchStatus }
  }
}

const getDisplayingParkings = (
  parkings: Parking[],
  searchFilters: (keyof typeof SearchFilterType)[],
  searchOrder: keyof typeof SearchOrderType,
  mapCenter: LatLng | null
): Parking[] => {
  if (parkings.length === 0) {
    return []
  }

  const filteredParkings = filterParkingsUseCase.call({
    parkings: parkings,
    types: searchFilters,
  })
  const sortedParkings = sortParkingsUseCase.call({
    parkings: filteredParkings,
    type: searchOrder,
    mapCenter: mapCenter ?? undefined,
  })
  return sortedParkings
}

type SearchParkingsReducer = [
  SearchParkingsState,
  Dispatch<SearchParkingsAction>
]

const SearchParkingsReducerContext =
  React.createContext<SearchParkingsReducer | null>(null)

export const useSearchParkingsReducer = (): SearchParkingsReducer => {
  const reducer = React.useContext(SearchParkingsReducerContext)
  if (reducer === null) {
    throw new Error(
      "useSearchParkingReducer must be used within a SearchParkingReducerProvider"
    )
  }
  return reducer
}

export const SearchParkingsReducerProvider = (props: {
  children: ReactElement
}): ReactElement => {
  const [state, dispatch] = React.useReducer(
    reducer,
    initialSearchParkingsState
  )

  return (
    <SearchParkingsReducerContext.Provider value={[state, dispatch]}>
      {props.children}
    </SearchParkingsReducerContext.Provider>
  )
}
